import { useAPI } from "../../utils/useAPI";
import { popularBackground } from "../../content/contentBackground";
import { tdrAlert, tdrLog } from "../../utils/utils";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

interface PhotoFeatured {
    numberOfImages?: number;
}

export const AIGrabBGIds: React.FC<PhotoFeatured> = ({ numberOfImages }) => {

    const api = useAPI();


    // Limit from vector
    const pageSize = numberOfImages ? numberOfImages : 20;
    const imageIds: string[] = [];
    const [output, setOutput] = useState([""]);


    useEffect(() => {
        getFeaturedImages();
    }, []);

    const getFeaturedImages = async () => {
        for (const item of popularBackground) {
            console.log("getting background " + item.t)
            let now = Date.now();
            let keyword = item.t + " background wallpaper";
            try {
                if (keyword) {
                    try {
                        const result = await api.getGeneratedImages({ dateFilter: { increasing: false, startDate: now }, keywords: { filterValue: keyword.replaceAll("-", " ") }, approved: { filterValue: true }, limit: (pageSize + 1) });
                        if (result && result.generatedImages) {
                            result.generatedImages.map((outputimage, index) => {
                                if (outputimage.imageId) {
                                    imageIds.push(outputimage.imageId)
                                }
                            })
                        }

                    } catch (err) {
                        tdrLog('api error - ' + err);
                        throw err;
                    }
                }
            }
            catch (e: any) {
                tdrAlert('error test')
            }
        };
        setOutput(imageIds);

    }


    return (
        <div>
            {output.map((item, index) => (
                <div key={index}>{item}</div>
            ))}
        </div>
    );
}
